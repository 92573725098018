<template>
  <div class="icon-app-store">
    <svg v-if="icon === 'en'" width="133" height="44" viewBox="0 0 133 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.25" y="0.5" width="131" height="43" rx="6.5" fill="black"/>
      <path d="M90.4293 21.1214V23.6415H88.8497V25.2942H90.4293V30.9097C90.4293 32.8271 91.2968 33.5941 93.479 33.5941C93.8625 33.5941 94.2278 33.5485 94.5473 33.4937V31.8593C94.2734 31.8867 94.0999 31.9049 93.7986 31.9049C92.8216 31.9049 92.3925 31.4484 92.3925 30.4075V25.2942H94.5473V23.6415H92.3925V21.1214H90.4293Z" fill="white"/>
      <path d="M100.107 33.7311C103.01 33.7311 104.791 31.7862 104.791 28.563C104.791 25.3581 103.001 23.4041 100.107 23.4041C97.203 23.4041 95.4133 25.3581 95.4133 28.563C95.4133 31.7862 97.1938 33.7311 100.107 33.7311ZM100.107 31.9871C98.3991 31.9871 97.4404 30.7362 97.4404 28.563C97.4404 26.4082 98.3991 25.1481 100.107 25.1481C101.805 25.1481 102.773 26.4082 102.773 28.563C102.773 30.727 101.805 31.9871 100.107 31.9871Z" fill="white"/>
      <path d="M106.314 33.5394H108.277V27.6682C108.277 26.2712 109.327 25.3307 110.816 25.3307C111.163 25.3307 111.747 25.3946 111.911 25.4494V23.5137C111.701 23.4589 111.327 23.4315 111.035 23.4315C109.738 23.4315 108.633 24.1437 108.35 25.1207H108.204V23.5958H106.314V33.5394Z" fill="white"/>
      <path d="M116.786 25.075C118.238 25.075 119.187 26.0886 119.233 27.6499H114.211C114.32 26.0977 115.334 25.075 116.786 25.075ZM119.224 30.8549C118.858 31.631 118.046 32.0602 116.859 32.0602C115.288 32.0602 114.275 30.9553 114.211 29.2113V29.1018H121.232V28.4169C121.232 25.2942 119.562 23.4041 116.795 23.4041C113.992 23.4041 112.211 25.422 112.211 28.5996C112.211 31.7771 113.955 33.7311 116.804 33.7311C119.078 33.7311 120.666 32.6354 121.114 30.8549H119.224Z" fill="white"/>
      <path d="M77.5554 29.8674C77.7068 32.3091 79.7415 33.8706 82.77 33.8706C86.0066 33.8706 88.0319 32.2333 88.0319 29.6213C88.0319 27.5677 86.8773 26.432 84.076 25.779L82.5712 25.4099C80.792 24.9935 80.0728 24.4351 80.0728 23.4603C80.0728 22.23 81.1895 21.4256 82.8646 21.4256C84.4545 21.4256 85.5524 22.2111 85.7511 23.4698H87.8142C87.6912 21.1701 85.6659 19.5518 82.893 19.5518C79.9119 19.5518 77.9245 21.1701 77.9245 23.6023C77.9245 25.6086 79.0507 26.8011 81.5207 27.3784L83.281 27.8042C85.0886 28.2301 85.8836 28.8547 85.8836 29.8958C85.8836 31.1071 84.6344 31.9873 82.9309 31.9873C81.1043 31.9873 79.8362 31.1639 79.6564 29.8674H77.5554Z" fill="white"/>
      <path d="M57.2193 23.4315C55.868 23.4315 54.6992 24.1072 54.0966 25.2394H53.9505V23.5958H52.0604V36.8447H54.0235V32.0328H54.1787C54.6992 33.0828 55.8223 33.7037 57.2376 33.7037C59.7486 33.7037 61.3465 31.7223 61.3465 28.563C61.3465 25.4038 59.7486 23.4315 57.2193 23.4315ZM56.6623 31.9415C55.0188 31.9415 53.987 30.6449 53.987 28.5722C53.987 26.4903 55.0188 25.1937 56.6715 25.1937C58.3333 25.1937 59.3285 26.4629 59.3285 28.563C59.3285 30.6723 58.3333 31.9415 56.6623 31.9415Z" fill="white"/>
      <path d="M68.2158 23.4315C66.8644 23.4315 65.6957 24.1072 65.093 25.2394H64.9469V23.5958H63.0569V36.8447H65.02V32.0328H65.1752C65.6957 33.0828 66.8188 33.7037 68.2341 33.7037C70.745 33.7037 72.3429 31.7223 72.3429 28.563C72.3429 25.4038 70.745 23.4315 68.2158 23.4315ZM67.6588 31.9415C66.0153 31.9415 64.9835 30.6449 64.9835 28.5722C64.9835 26.4903 66.0153 25.1937 67.6679 25.1937C69.3298 25.1937 70.325 26.4629 70.325 28.563C70.325 30.6723 69.3298 31.9415 67.6588 31.9415Z" fill="white"/>
      <path d="M48.5382 33.5394H50.7906L45.8599 19.883H43.5791L38.6484 33.5394H40.8251L42.0838 29.9147H47.2889L48.5382 33.5394ZM44.6107 22.3625H44.7716L46.7495 28.1355H42.6233L44.6107 22.3625Z" fill="white"/>
      <path d="M39.9668 9.58196V16.1699H42.3454C44.3085 16.1699 45.4453 14.96 45.4453 12.8554C45.4453 10.7827 44.2994 9.58196 42.3454 9.58196H39.9668ZM40.9895 10.5133H42.2313C43.5963 10.5133 44.4044 11.3807 44.4044 12.8691C44.4044 14.3802 43.61 15.2385 42.2313 15.2385H40.9895V10.5133Z" fill="white"/>
      <path d="M48.9269 16.2657C50.3787 16.2657 51.269 15.2933 51.269 13.6817C51.269 12.0792 50.3742 11.1022 48.9269 11.1022C47.4751 11.1022 46.5803 12.0792 46.5803 13.6817C46.5803 15.2933 47.4705 16.2657 48.9269 16.2657ZM48.9269 15.3937C48.0732 15.3937 47.5938 14.7683 47.5938 13.6817C47.5938 12.6043 48.0732 11.9742 48.9269 11.9742C49.7761 11.9742 50.26 12.6043 50.26 13.6817C50.26 14.7637 49.7761 15.3937 48.9269 15.3937Z" fill="white"/>
      <path d="M58.8503 11.1981H57.8688L56.9831 14.992H56.9055L55.8828 11.1981H54.9423L53.9197 14.992H53.8466L52.9564 11.1981H51.9611L53.3307 16.1699H54.3397L55.3623 12.5084H55.44L56.4672 16.1699H57.4853L58.8503 11.1981Z" fill="white"/>
      <path d="M59.9807 16.1699H60.9623V13.2617C60.9623 12.4856 61.4234 12.0016 62.1493 12.0016C62.8752 12.0016 63.2222 12.3988 63.2222 13.1978V16.1699H64.2038V12.9512C64.2038 11.7688 63.592 11.1022 62.4826 11.1022C61.7339 11.1022 61.2408 11.4355 60.9988 11.9879H60.9258V11.1981H59.9807V16.1699Z" fill="white"/>
      <path d="M65.7496 16.1699H66.7312V9.25781H65.7496V16.1699Z" fill="white"/>
      <path d="M70.4228 16.2657C71.8746 16.2657 72.7648 15.2933 72.7648 13.6817C72.7648 12.0792 71.87 11.1022 70.4228 11.1022C68.971 11.1022 68.0762 12.0792 68.0762 13.6817C68.0762 15.2933 68.9664 16.2657 70.4228 16.2657ZM70.4228 15.3937C69.569 15.3937 69.0897 14.7683 69.0897 13.6817C69.0897 12.6043 69.569 11.9742 70.4228 11.9742C71.272 11.9742 71.7559 12.6043 71.7559 13.6817C71.7559 14.7637 71.272 15.3937 70.4228 15.3937Z" fill="white"/>
      <path d="M75.6895 15.4257C75.1553 15.4257 74.7672 15.1655 74.7672 14.7181C74.7672 14.2798 75.0777 14.0469 75.7625 14.0013L76.9769 13.9237V14.3391C76.9769 14.9555 76.4291 15.4257 75.6895 15.4257ZM75.4384 16.2521C76.0912 16.2521 76.6345 15.969 76.9313 15.4714H77.0089V16.1699H77.9539V12.7732C77.9539 11.7231 77.2508 11.1022 76.0045 11.1022C74.8768 11.1022 74.0733 11.6501 73.9729 12.5038H74.9225C75.032 12.1523 75.411 11.9514 75.9588 11.9514C76.6299 11.9514 76.9769 12.2482 76.9769 12.7732V13.2023L75.6301 13.28C74.4477 13.353 73.7811 13.8689 73.7811 14.7637C73.7811 15.6722 74.4796 16.2521 75.4384 16.2521Z" fill="white"/>
      <path d="M81.2849 16.2521C81.9697 16.2521 82.5495 15.9279 82.8462 15.3846H82.9238V16.1699H83.8643V9.25781H82.8828V11.9879H82.8097C82.5403 11.4401 81.9651 11.1159 81.2849 11.1159C80.0294 11.1159 79.2213 12.1112 79.2213 13.6817C79.2213 15.2568 80.0202 16.2521 81.2849 16.2521ZM81.5633 11.9971C82.3851 11.9971 82.901 12.6499 82.901 13.6863C82.901 14.7272 82.3897 15.3709 81.5633 15.3709C80.7324 15.3709 80.2348 14.7363 80.2348 13.6817C80.2348 12.6362 80.737 11.9971 81.5633 11.9971Z" fill="white"/>
      <path d="M90.2294 16.2657C91.6813 16.2657 92.5715 15.2933 92.5715 13.6817C92.5715 12.0792 91.6767 11.1022 90.2294 11.1022C88.7776 11.1022 87.8828 12.0792 87.8828 13.6817C87.8828 15.2933 88.7731 16.2657 90.2294 16.2657ZM90.2294 15.3937C89.3757 15.3937 88.8963 14.7683 88.8963 13.6817C88.8963 12.6043 89.3757 11.9742 90.2294 11.9742C91.0786 11.9742 91.5625 12.6043 91.5625 13.6817C91.5625 14.7637 91.0786 15.3937 90.2294 15.3937Z" fill="white"/>
      <path d="M93.8708 16.1699H94.8524V13.2617C94.8524 12.4856 95.3135 12.0016 96.0394 12.0016C96.7653 12.0016 97.1123 12.3988 97.1123 13.1978V16.1699H98.0939V12.9512C98.0939 11.7688 97.4821 11.1022 96.3727 11.1022C95.624 11.1022 95.1309 11.4355 94.8889 11.9879H94.8159V11.1981H93.8708V16.1699Z" fill="white"/>
      <path d="M102.615 9.96089V11.2209H101.825V12.0473H102.615V14.855C102.615 15.8138 103.048 16.1973 104.139 16.1973C104.331 16.1973 104.514 16.1744 104.674 16.147V15.3298C104.537 15.3435 104.45 15.3527 104.299 15.3527C103.811 15.3527 103.596 15.1244 103.596 14.6039V12.0473H104.674V11.2209H103.596V9.96089H102.615Z" fill="white"/>
      <path d="M105.991 16.1699H106.973V13.2663C106.973 12.513 107.42 12.0062 108.224 12.0062C108.918 12.0062 109.287 12.408 109.287 13.2023V16.1699H110.269V12.9604C110.269 11.7779 109.616 11.1068 108.557 11.1068C107.808 11.1068 107.283 11.4401 107.041 11.9971H106.964V9.25781H105.991V16.1699Z" fill="white"/>
      <path d="M113.81 11.9377C114.536 11.9377 115.011 12.4445 115.033 13.2252H112.522C112.577 12.449 113.084 11.9377 113.81 11.9377ZM115.029 14.8276C114.846 15.2157 114.44 15.4303 113.846 15.4303C113.061 15.4303 112.554 14.8779 112.522 14.0059V13.9511H116.033V13.6087C116.033 12.0473 115.198 11.1022 113.814 11.1022C112.413 11.1022 111.523 12.1112 111.523 13.7C111.523 15.2887 112.395 16.2657 113.819 16.2657C114.956 16.2657 115.75 15.7179 115.974 14.8276H115.029Z" fill="white"/>
      <path d="M27.9958 22.3312C28.0196 20.4821 29.0127 18.7324 30.588 17.7639C29.5942 16.3446 27.9296 15.4446 26.1977 15.3905C24.3506 15.1966 22.5598 16.4958 21.6185 16.4958C20.659 16.4958 19.2098 15.4097 17.6492 15.4418C15.615 15.5075 13.7187 16.6641 12.729 18.4425C10.6016 22.1257 12.1884 27.5389 14.2263 30.5162C15.2459 31.974 16.4375 33.6025 17.9967 33.5448C19.5225 33.4815 20.0923 32.5719 21.934 32.5719C23.7587 32.5719 24.2933 33.5448 25.8841 33.5081C27.5214 33.4815 28.5529 32.0437 29.5367 30.572C30.2693 29.5332 30.833 28.3851 31.207 27.1703C29.2829 26.3565 27.998 24.4202 27.9958 22.3312Z" fill="white"/>
      <path d="M24.991 13.4325C25.8836 12.3608 26.3234 10.9834 26.2169 9.59277C24.8531 9.73602 23.5933 10.3878 22.6886 11.4183C21.8039 12.4252 21.3435 13.7784 21.4305 15.1159C22.7948 15.1299 24.136 14.4958 24.991 13.4325Z" fill="white"/>
      <rect x="1.25" y="0.5" width="131" height="43" rx="6.5" stroke="#A6A6A6"/>
    </svg>

    <svg v-if="icon === 'ar'" width="132" height="44" viewBox="0 0 132 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2273_38526)">
        <path d="M10.5176 0.000143C10.1131 0.000143 9.71344 0.000143 9.31001 0.00234299C8.9723 0.00454299 8.63728 0.010934 8.29627 0.0163129C7.55558 0.0250078 6.81663 0.0899895 6.08585 0.210693C5.35607 0.334028 4.64915 0.566549 3.98901 0.900391C3.32967 1.23706 2.72721 1.67452 2.2035 2.19691C1.67703 2.71783 1.23818 3.31995 0.903815 3.98011C0.568532 4.63895 0.336045 5.34496 0.214385 6.07375C0.0915684 6.80162 0.0254795 7.53789 0.0167118 8.27594C0.00647513 8.6132 0.0053941 8.95158 0 9.28889V34.7145C0.0053941 35.056 0.00647513 35.3869 0.0167118 35.7286C0.0254823 36.4666 0.0915711 37.2028 0.214385 37.9307C0.335709 38.6599 0.56821 39.3663 0.903815 40.0254C1.23802 40.6834 1.67694 41.2831 2.2035 41.8011C2.72523 42.3258 3.32807 42.7636 3.98901 43.0977C4.64915 43.4324 5.35599 43.6663 6.08585 43.7916C6.81677 43.9113 7.55566 43.9763 8.29632 43.986C8.63728 43.9935 8.9723 43.9978 9.31006 43.9978C9.71343 44 10.1131 44 10.5176 44H121.488C121.885 44 122.288 44 122.684 43.9978C123.02 43.9978 123.365 43.9935 123.701 43.986C124.44 43.9768 125.178 43.9118 125.907 43.7916C126.639 43.6654 127.349 43.4316 128.012 43.0977C128.672 42.7634 129.275 42.3256 129.796 41.8011C130.321 41.2811 130.761 40.6818 131.099 40.0254C131.433 39.3658 131.663 38.6595 131.782 37.9307C131.905 37.2027 131.974 36.4667 131.987 35.7286C131.991 35.3869 131.991 35.056 131.991 34.7145C132 34.3149 132 33.9174 132 33.5113V10.4899C132 10.087 132 9.68742 131.991 9.28889C131.991 8.95158 131.991 8.6132 131.987 8.2759C131.974 7.53778 131.905 6.80168 131.782 6.07371C131.662 5.34534 131.432 4.63938 131.099 3.98006C130.419 2.65685 129.339 1.57976 128.012 0.900292C127.349 0.567266 126.639 0.334807 125.907 0.210594C125.178 0.0893556 124.44 0.0243513 123.701 0.0161589C123.365 0.010791 123.02 0.00434499 122.684 0.00219999C122.288 0 121.885 0 121.488 0" fill="#A6A6A6"/>
        <path d="M122.679 0.962891L123.686 0.976861C124.373 0.985 125.058 1.04479 125.736 1.15571C126.377 1.26426 126.998 1.46737 127.579 1.75834C128.724 2.34667 129.655 3.27746 130.243 4.42026C130.53 4.99171 130.729 5.60323 130.833 6.23406C130.948 6.92046 131.012 7.61428 131.025 8.30999C131.028 8.62151 131.028 8.95612 131.028 9.28914C131.037 9.70164 131.037 10.0943 131.037 10.4901V33.5116C131.037 33.9112 131.037 34.3011 131.028 34.6943C131.028 35.052 131.028 35.3797 131.024 35.7169C131.011 36.4002 130.948 37.0817 130.835 37.7558C130.732 38.395 130.532 39.0145 130.24 39.5928C129.949 40.1564 129.571 40.6708 129.119 41.117C128.669 41.5722 128.148 41.952 127.576 42.2418C126.996 42.5344 126.376 42.7383 125.736 42.8466C125.054 42.9576 124.365 43.0176 123.674 43.026C123.351 43.0335 123.012 43.0378 122.684 43.0378L121.488 43.0399L9.31528 43.0378C8.9792 43.0378 8.65123 43.0335 8.31777 43.026C7.627 43.017 6.93784 42.957 6.25595 42.8466C5.62012 42.7374 5.00417 42.5342 4.42842 42.2439C3.85795 41.9559 3.33763 41.5784 2.8874 41.1256C2.43066 40.6782 2.0504 40.1593 1.76169 39.5895C1.4698 39.0159 1.2678 38.4012 1.16271 37.7666C1.04923 37.0847 0.987836 36.3952 0.979051 35.7041C0.972057 35.4721 0.962891 34.6997 0.962891 34.6997V9.28914C0.962891 9.28914 0.972653 8.52859 0.979106 8.30515C0.987518 7.6151 1.04856 6.92669 1.1617 6.24588C1.26698 5.60955 1.46914 4.993 1.76118 4.41757C2.04883 3.8481 2.42698 3.32879 2.88097 2.87978C3.33446 2.42653 3.85644 2.04703 4.42788 1.75512C5.0023 1.46569 5.61709 1.264 6.25163 1.15679C6.93577 1.04521 7.62732 0.984886 8.32049 0.976322L9.31583 0.962891" fill="black"/>
        <path d="M118.317 22.3315C118.329 21.4134 118.573 20.5132 119.028 19.7146C119.482 18.9161 120.132 18.2452 120.916 17.7643C120.418 17.0546 119.761 16.4706 118.996 16.0585C118.232 15.6465 117.382 15.4179 116.514 15.3908C114.661 15.1969 112.866 16.4961 111.922 16.4961C110.96 16.4961 109.506 15.4101 107.941 15.4422C106.929 15.4748 105.942 15.7683 105.078 16.2942C104.213 16.8201 103.5 17.5603 103.007 18.4428C100.874 22.1261 102.465 27.5392 104.509 30.5165C105.531 31.9744 106.726 33.6028 108.29 33.5451C109.82 33.4818 110.391 32.5722 112.238 32.5722C114.068 32.5722 114.604 33.5451 116.199 33.5084C117.841 33.4818 118.876 32.044 119.862 30.5723C120.597 29.5335 121.162 28.3855 121.537 27.1706C120.583 26.7683 119.769 26.0949 119.197 25.2344C118.624 24.3738 118.318 23.3643 118.317 22.3315Z" fill="white"/>
        <path d="M115.304 13.4324C116.199 12.3608 116.64 10.9834 116.533 9.59277C115.166 9.73602 113.902 10.3878 112.995 11.4183C112.551 11.9218 112.212 12.5074 111.995 13.1419C111.779 13.7763 111.69 14.4471 111.733 15.1159C112.418 15.1229 113.094 14.975 113.713 14.6834C114.331 14.3918 114.875 13.9641 115.304 13.4324Z" fill="white"/>
        <path d="M18.972 29.8534H13.7506L12.4967 33.5455H10.2852L15.2307 19.8857H17.5285L22.4741 33.5455H20.2248L18.972 29.8534ZM14.2914 28.1497H18.4302L16.3899 22.1577H16.3328L14.2914 28.1497Z" fill="white"/>
        <path d="M33.1555 28.5666C33.1555 31.6614 31.4944 33.6497 28.9877 33.6497C28.3527 33.6829 27.7212 33.537 27.1655 33.2289C26.6097 32.9207 26.1523 32.4627 25.8454 31.9074H25.798V36.8402H23.748V23.5865H25.7323V25.2429H25.77C26.0909 24.6902 26.5561 24.2346 27.1161 23.9246C27.6761 23.6146 28.3098 23.4617 28.95 23.4823C31.4847 23.4823 33.1555 25.4803 33.1555 28.5666ZM31.0485 28.5666C31.0485 26.5503 30.0035 25.2247 28.4092 25.2247C26.8429 25.2247 25.7894 26.5782 25.7894 28.5666C25.7894 30.5732 26.8429 31.917 28.4092 31.917C30.0035 31.917 31.0485 30.6011 31.0485 28.5666Z" fill="white"/>
        <path d="M44.1478 28.5666C44.1478 31.6614 42.4867 33.6498 39.98 33.6498C39.345 33.6829 38.7134 33.537 38.1577 33.2289C37.602 32.9207 37.1445 32.4627 36.8377 31.9074H36.7902V36.8402H34.7402V23.5865H36.7245V25.2429H36.7622C37.0831 24.6902 37.5483 24.2346 38.1083 23.9246C38.6683 23.6146 39.302 23.4617 39.9422 23.4823C42.477 23.4823 44.1478 25.4803 44.1478 28.5666ZM42.0407 28.5666C42.0407 26.5503 40.9958 25.2247 39.4015 25.2247C37.8352 25.2247 36.7816 26.5782 36.7816 28.5666C36.7816 30.5732 37.8352 31.9171 39.4015 31.9171C40.9958 31.9171 42.0407 30.6011 42.0407 28.5666Z" fill="white"/>
        <path d="M51.411 29.7397C51.5629 31.0943 52.8825 31.9837 54.6858 31.9837C56.4136 31.9837 57.6568 31.0943 57.6568 29.8729C57.6568 28.8126 56.907 28.1778 55.1317 27.7427L53.3565 27.3163C50.8411 26.7104 49.6734 25.5374 49.6734 23.6339C49.6734 21.277 51.7331 19.6582 54.6578 19.6582C57.5523 19.6582 59.5366 21.277 59.6034 23.6339H57.534C57.4101 22.2707 56.2801 21.4479 54.6287 21.4479C52.9773 21.4479 51.8472 22.2804 51.8472 23.4921C51.8472 24.4578 52.569 25.0261 54.3346 25.4611L55.8438 25.8306C58.6543 26.4934 59.822 27.6192 59.822 29.6172C59.822 32.1728 57.7806 33.7734 54.5339 33.7734C51.4961 33.7734 49.445 32.2104 49.3125 29.7397L51.411 29.7397Z" fill="white"/>
        <path d="M64.2461 21.2295V23.5863H66.1453V25.2052H64.2461V30.6955C64.2461 31.5484 64.6264 31.9459 65.4612 31.9459C65.6867 31.9419 65.9118 31.9262 66.1356 31.8986V33.5077C65.7602 33.5777 65.3787 33.6093 64.9969 33.6023C62.975 33.6023 62.1864 32.8449 62.1864 30.9135V25.2052H60.7344V23.5863H62.1865V21.2295H64.2461Z" fill="white"/>
        <path d="M67.2461 28.5664C67.2461 25.4329 69.0968 23.4639 71.9827 23.4639C74.8783 23.4639 76.7204 25.4329 76.7204 28.5664C76.7204 31.7085 74.888 33.6689 71.9827 33.6689C69.0785 33.6689 67.2461 31.7085 67.2461 28.5664ZM74.6316 28.5664C74.6316 26.4169 73.6438 25.1482 71.9827 25.1482C70.3216 25.1482 69.3348 26.4266 69.3348 28.5664C69.3348 30.7245 70.3216 31.9835 71.9827 31.9835C73.6438 31.9835 74.6316 30.7245 74.6316 28.5664Z" fill="white"/>
        <path d="M78.4102 23.5864H80.3653V25.2815H80.4128C80.5451 24.7521 80.8561 24.2842 81.2936 23.9566C81.731 23.6289 82.2683 23.4614 82.815 23.4822C83.0513 23.4814 83.2868 23.507 83.5174 23.5585V25.4706C83.2191 25.3797 82.908 25.338 82.5963 25.347C82.2985 25.335 82.0016 25.3874 81.726 25.5005C81.4504 25.6137 81.2026 25.785 80.9997 26.0027C80.7967 26.2204 80.6434 26.4793 80.5503 26.7616C80.4572 27.0439 80.4264 27.343 80.4601 27.6383V33.5455H78.4102L78.4102 23.5864Z" fill="white"/>
        <path d="M92.9698 30.6203C92.6941 32.4282 90.9285 33.6689 88.6695 33.6689C85.7642 33.6689 83.9609 31.7278 83.9609 28.6137C83.9609 25.4898 85.7739 23.4639 88.5834 23.4639C91.3465 23.4639 93.084 25.3566 93.084 28.3763V29.0766H86.0303V29.2002C85.9978 29.5667 86.0438 29.9359 86.1653 30.2833C86.2868 30.6308 86.4811 30.9484 86.7352 31.2152C86.9893 31.482 87.2974 31.6919 87.6391 31.8308C87.9808 31.9697 88.3482 32.0346 88.7169 32.0211C89.2014 32.0663 89.6875 31.9544 90.1031 31.7021C90.5186 31.4497 90.8413 31.0703 91.0233 30.6203L92.9698 30.6203ZM86.04 27.6479H91.033C91.0505 27.3339 91.0059 27.0195 90.9015 26.7227C90.7972 26.4259 90.6353 26.1524 90.425 25.918C90.2148 25.6836 89.9602 25.4928 89.676 25.3564C89.3918 25.2201 89.0834 25.1409 88.7685 25.1235C88.7068 25.12 88.6451 25.119 88.5834 25.1203C88.2513 25.1183 87.9222 25.1816 87.6147 25.3065C87.3072 25.4313 87.0274 25.6154 86.7912 25.8481C86.555 26.0808 86.3671 26.3576 86.2383 26.6628C86.1094 26.9679 86.042 27.2953 86.04 27.6264C86.04 27.6336 86.04 27.6408 86.04 27.6479Z" fill="white"/>
        <path d="M59.3402 17.6918H58.6195C57.2164 17.6918 55.7363 17.4044 55.7363 15.4681C55.8304 14.5975 56.0305 13.7415 56.332 12.919L57.2643 13.1682L57.1297 13.5995C56.9302 14.2062 56.7982 14.8328 56.7359 15.4681C56.7359 16.8001 58.0141 16.8001 58.8791 16.8001H59.4845C59.917 16.8001 60.5127 16.6277 60.5127 16.0815V12.296H61.5415V15.133C61.7631 15.2267 62.0021 15.2723 62.2428 15.2667H62.5983V16.1486H62.2428C62.0052 16.147 61.769 16.1116 61.5415 16.0434V16.1776C61.5416 16.6756 61.157 17.6918 59.3402 17.6918ZM58.7542 11.261C58.6333 11.2546 58.5169 11.213 58.4194 11.1414C58.3218 11.0699 58.2474 10.9714 58.2053 10.8582C58.1633 10.745 58.1553 10.6219 58.1825 10.5043C58.2097 10.3866 58.2708 10.2794 58.3583 10.196C58.4458 10.1125 58.5559 10.0564 58.675 10.0346C58.7941 10.0128 58.917 10.0262 59.0285 10.0732C59.1401 10.1202 59.2354 10.1987 59.3028 10.299C59.3701 10.3994 59.4065 10.5172 59.4075 10.6379C59.412 10.7991 59.352 10.9555 59.2408 11.0726C59.1296 11.1897 58.9763 11.2579 58.8147 11.2623C58.7945 11.2629 58.7743 11.2624 58.7542 11.261Z" fill="white"/>
        <path d="M63.7581 15.267C63.6397 14.8462 63.5846 14.4101 63.5944 13.9731C63.5641 13.6841 63.5957 13.392 63.6873 13.1161C63.7788 12.8402 63.9282 12.5869 64.1254 12.373C64.3227 12.1591 64.5633 11.9895 64.8313 11.8754C65.0993 11.7613 65.3886 11.7054 65.6799 11.7114C67.0448 11.7114 67.5737 12.8232 67.5737 14.1461C67.5737 15.1236 67.2273 16.446 65.8916 16.446C65.4512 16.4036 65.0182 16.3037 64.6038 16.149H62.4219V15.267L63.7581 15.267ZM64.7961 15.3057C65.1226 15.4435 65.4703 15.5245 65.8243 15.5452C66.4199 15.5452 66.5837 14.7879 66.5837 14.1171C66.5837 13.4752 66.4011 12.5552 65.6223 12.5552C64.921 12.5552 64.6135 13.2067 64.6135 14.0311C64.6004 14.4633 64.6622 14.8945 64.7961 15.3057Z" fill="white"/>
        <path d="M74.3583 17.6924H73.6377C72.234 17.6924 70.7539 17.405 70.7539 15.4687C70.8483 14.5981 71.0483 13.7422 71.3496 12.9196L72.2825 13.1688L72.1478 13.6001C71.9482 14.2067 71.8159 14.8333 71.7535 15.4687C71.7535 16.8007 73.0322 16.8007 73.8972 16.8007H74.5026C74.9346 16.8007 75.5303 16.6283 75.5303 16.0821V9.68945H76.5591V15.1336C76.7807 15.2273 77.0197 15.2729 77.2604 15.2673H77.6159V16.1492H77.2604C77.0228 16.1475 76.7866 16.1121 76.5591 16.044V16.1782C76.5591 16.6761 76.1746 17.6924 74.3583 17.6924Z" fill="white"/>
        <path d="M81.073 11.7598V14.4528C81.073 15.5931 80.39 16.149 79.0348 16.149H77.4395V15.2671H78.9971C79.8137 15.2671 80.0345 14.9126 80.0345 14.3089V11.7598H81.073ZM78.4972 18.2958C78.3763 18.2893 78.26 18.2477 78.1625 18.1761C78.0651 18.1044 77.9908 18.0059 77.9487 17.8927C77.9067 17.7795 77.8989 17.6564 77.9261 17.5388C77.9533 17.4212 78.0145 17.314 78.102 17.2306C78.1895 17.1472 78.2996 17.0912 78.4187 17.0694C78.5378 17.0476 78.6607 17.0611 78.7722 17.1081C78.8837 17.1551 78.979 17.2336 79.0464 17.3339C79.1137 17.4343 79.1501 17.552 79.1511 17.6728C79.1534 17.7577 79.1379 17.8422 79.1055 17.9208C79.073 17.9994 79.0245 18.0703 78.9629 18.129C78.9012 18.1877 78.828 18.2329 78.7478 18.2616C78.6676 18.2903 78.5822 18.302 78.4972 18.2958ZM80.112 18.2958C79.9911 18.2895 79.8746 18.2481 79.777 18.1765C79.6794 18.105 79.605 18.0066 79.5628 17.8934C79.5206 17.7802 79.5126 17.6571 79.5397 17.5394C79.5668 17.4217 79.6279 17.3145 79.7154 17.231C79.8029 17.1475 79.913 17.0913 80.0321 17.0695C80.1512 17.0476 80.2741 17.061 80.3857 17.108C80.4973 17.1549 80.5926 17.2334 80.66 17.3338C80.7274 17.4342 80.7638 17.552 80.7648 17.6728C80.7692 17.834 80.7092 17.9903 80.598 18.1074C80.4868 18.2245 80.3336 18.2927 80.1719 18.2971C80.152 18.2977 80.132 18.2972 80.112 18.2958Z" fill="white"/>
        <path d="M81.8397 17.5866C81.9863 17.6045 82.1338 17.614 82.2814 17.6151C83.1281 17.6151 83.5504 17.1263 83.5504 16.1391V12.2864H84.5888V15.2668H85.54V16.1488H84.5792C84.5906 16.8021 84.3535 17.4356 83.9156 17.9218C83.4485 18.3115 82.8522 18.5132 82.2437 18.4873C82.025 18.4839 81.8067 18.4679 81.5898 18.4395L81.8397 17.5866ZM84.0211 11.3567C83.9002 11.3502 83.7839 11.3086 83.6864 11.2369C83.589 11.1653 83.5147 11.0668 83.4726 10.9536C83.4306 10.8403 83.4228 10.7173 83.45 10.5997C83.4772 10.482 83.5384 10.3749 83.6259 10.2915C83.7135 10.2081 83.8235 10.1521 83.9426 10.1303C84.0617 10.1085 84.1846 10.1219 84.2961 10.1689C84.4076 10.2159 84.5029 10.2944 84.5703 10.3948C84.6376 10.4951 84.674 10.6129 84.675 10.7336C84.6774 10.8186 84.6619 10.9031 84.6295 10.9817C84.5971 11.0603 84.5485 11.1313 84.4869 11.19C84.4252 11.2487 84.3519 11.2939 84.2717 11.3226C84.1915 11.3513 84.1061 11.3629 84.0211 11.3567Z" fill="white"/>
        <path d="M88.9502 16.149C88.5358 16.1362 88.1306 16.0244 87.7685 15.823C87.2709 16.0886 86.7059 16.202 86.144 16.149H85.3652V15.2671H86.2023C86.9897 15.2671 87.1438 15.1425 87.1438 14.5678V11.7598H88.1725V15.0947C88.415 15.211 88.6812 15.2699 88.9503 15.2671H89.5945V16.149L88.9502 16.149ZM87.6145 10.801C87.4936 10.7945 87.3772 10.7529 87.2797 10.6813C87.1823 10.6096 87.108 10.5111 87.066 10.3979C87.0239 10.2847 87.0161 10.1617 87.0433 10.044C87.0705 9.92638 87.1317 9.81925 87.2192 9.73584C87.3068 9.65243 87.4169 9.59639 87.5359 9.57461C87.655 9.55282 87.7779 9.56626 87.8894 9.61326C88.0009 9.66026 88.0962 9.73877 88.1636 9.83911C88.2309 9.93946 88.2673 10.0573 88.2683 10.178C88.2707 10.2629 88.2552 10.3474 88.2228 10.4261C88.1904 10.5047 88.1418 10.5756 88.0802 10.6343C88.0185 10.693 87.9453 10.7382 87.865 10.7669C87.7848 10.7956 87.6995 10.8073 87.6145 10.801Z" fill="white"/>
        <path d="M92.0537 11.7598V14.4528C92.0537 15.5931 91.3719 16.149 90.0167 16.149H89.4199V15.2671H89.9779C90.7945 15.2671 91.0153 14.9126 91.0153 14.3089V11.7598H92.0537ZM90.5252 10.801C90.4043 10.7945 90.2879 10.7529 90.1904 10.6813C90.093 10.6096 90.0187 10.5111 89.9767 10.3979C89.9346 10.2847 89.9268 10.1617 89.954 10.044C89.9812 9.92638 90.0424 9.81925 90.1299 9.73584C90.2175 9.65243 90.3275 9.59639 90.4466 9.57461C90.5657 9.55282 90.6886 9.56626 90.8001 9.61326C90.9116 9.66026 91.0069 9.73877 91.0743 9.83911C91.1416 9.93946 91.178 10.0573 91.179 10.178C91.1814 10.2629 91.1659 10.3474 91.1335 10.4261C91.1011 10.5047 91.0525 10.5756 90.9909 10.6343C90.9292 10.693 90.856 10.7382 90.7757 10.7669C90.6955 10.7956 90.6102 10.8073 90.5252 10.801ZM92.1399 10.801C92.019 10.7945 91.9027 10.7529 91.8052 10.6813C91.7078 10.6096 91.6334 10.5111 91.5914 10.3979C91.5494 10.2847 91.5415 10.1617 91.5688 10.044C91.596 9.92638 91.6572 9.81925 91.7447 9.73584C91.8322 9.65243 91.9423 9.59639 92.0614 9.57461C92.1805 9.55282 92.3034 9.56626 92.4149 9.61326C92.5264 9.66026 92.6217 9.73877 92.689 9.83911C92.7564 9.93946 92.7928 10.0573 92.7938 10.178C92.7962 10.2629 92.7807 10.3474 92.7483 10.4261C92.7158 10.5047 92.6673 10.5756 92.6056 10.6343C92.544 10.693 92.4707 10.7382 92.3905 10.7669C92.3103 10.7956 92.2249 10.8073 92.1399 10.801Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2273_38526">
          <rect width="132" height="44" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
export default {
  props: {
    icon: String
  }
}
</script>
