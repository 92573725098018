<template>
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6893 0.93934C12.2751 0.353553 13.2249 0.353553 13.8107 0.93934L22.8107 9.93934C23.3964 10.5251 23.3964 11.4749 22.8107 12.0607L13.8107 21.0607C13.2249 21.6464 12.2751 21.6464 11.6893 21.0607C11.1036 20.4749 11.1036 19.5251 11.6893 18.9393L18.1287 12.5H2.25C1.42157 12.5 0.75 11.8284 0.75 11C0.75 10.1716 1.42157 9.5 2.25 9.5H18.1287L11.6893 3.06066C11.1036 2.47487 11.1036 1.52513 11.6893 0.93934Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
